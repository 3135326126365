/* tslint:disable:max-classes-per-file */
export class Force {
    id: number;
    name: string;
    description: string;
    prefix: string;
}

export class Department {
    id: number;
    name: string;
    description: string;
    prefix: string;
    requiresDepartmentApproval: boolean;
    requiresIMApproval: boolean;
}

export class DepartmentArea {
    id: number;
    name: string;
    description: string;
    prefix: string;
    requiresDepartmentApproval: boolean;
    requiresIMApproval: boolean;
    hasDepartmentTerm: boolean;
}

export class DepartmentTeam {
    id: number;
    name: string;
    description: string;
}

export class Template {
    id: number;
    name: string;
    description: string;
    type: string;
    internalName: string;
    autoGenerateUrl: boolean;
    mailboxPattern: string;
}

export class User {
    displayName: string;
    email: string;
    loginName: string;
    userId: string;
}