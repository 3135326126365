import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import { NewSiteWizardViewModel } from './NewSiteWizardViewModel';
import { PageSettings, InitialData } from './WizardSettings';
import { MyRequestModel } from './MyRequestModel';
import * as http from './http-helpers';
import * as microsoftTeams from "@microsoft/teams-js";
import { User } from './SharePointModels';
import { ProxyProvider, Providers } from '@microsoft/mgt';

export class AppPageViewModel {
    constructor(settings: PageSettings, initialData: InitialData) {
        if (!settings) {
            throw new Error("settings cannot be null");
        }

        if (!settings.wizardAppUrl) {
            throw new Error("wizardAppUrl cannot be null");
        }

        if (!settings.teamsAppClientId) {
            throw new Error("teamsAppClientId cannot be null");
        }

        this.settings = settings;

        try {
            microsoftTeams.initialize();

            const self = this;
            const resourceId = settings.wizardAppUrl.replace("https://", "") + "/" + settings.teamsAppClientId;
            Providers.globalProvider = new ProxyProvider(settings.graphProxyUrl, () => {
                return new Promise((resolve, reject) => {
                    const authTokenRequest = {
                        successCallback(result: string) {
                            const decoded = self.parseJwt(result);

                            if (decoded) {
                                const thisUser = new User();
                                thisUser.displayName = decoded.name;
                                thisUser.email = decoded.upn;
                                thisUser.userId = decoded.oid;
                                self.CurrentUser(thisUser);
                                self.Wizard().CurrentUser(thisUser);
                                self.CurrentUserStatus("loaded");
                                resolve({ "Authorization": result });
                            } else {
                                console.log("Error decoding auth token");
                                self.CurrentUserStatus("failed");
                                reject();
                            }
                        },
                        failureCallback(error: string) {
                            console.log("Error getting auth token from Teams: " + error);
                            self.CurrentUserStatus("failed");
                            reject();
                        },
                        resources: [resourceId]
                    };
                    microsoftTeams.authentication.getAuthToken(authTokenRequest);
                });
            });
        } catch (error) {
            // failed to get the users identity
            console.error("Unable to parse the users identity - " + error);
            this.CurrentUserStatus("failed");
        }

        this.Wizard(new NewSiteWizardViewModel(settings, initialData));
        this.Wizard().InitialiseWizard();

        this.CurrentPage.subscribe((newValue) => {
            if (newValue === 'myRequests') {
                this.LoadMyRequests().then((requests) => {
                    this.MyRequests(requests);
                });
            }
        });
    }

    settings: PageSettings;

    CurrentUser: Observable<User> = ko.observable(null);
    CurrentUserStatus: Observable<string> = ko.observable("loading");

    Wizard: Observable<NewSiteWizardViewModel> = ko.observable(null);

    MyRequests: ObservableArray<MyRequestModel> = ko.observableArray([]);

    CurrentPage: Observable<string> = ko.observable("wizard");

    public ChangePageTo(name: string) {
        this.CurrentPage(name);
    }

    private async LoadMyRequests(): Promise<MyRequestModel[]> {
        try {
            if (this.CurrentUser()) {
                // Note: need trailing slash for email address to be recognised as parameter to web api
                const url: string = this.settings.fetchRequestsUrl + "/" + this.CurrentUser().email + "/";
                const departmentsResponse = http.get<MyRequestModel[]>(url);
                return (await departmentsResponse).parsedBody;
            }
        }
        catch (error) {
            console.error("Failed to load requests for the current user " + error);
        }
    }

    private parseJwt(token: string) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
}