/*
 * A response object from a http request.
 */
interface HttpResponse<T> extends Response {
    parsedBody?: T;
}

/*
 * helper method to call a web service.
 */
export async function http<T>(
    request: RequestInfo
): Promise<HttpResponse<T>> {
    const response: HttpResponse<T> = await fetch(
        request
    );

    try {
        // may error if there is no body
        response.parsedBody = await response.json();
    } catch (ex) {
        // Ignore error
    }

    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return response;
}

/*
 * Helper method to make a GET request to a web service
 */
export async function get<T>(
    path: string,
    args: RequestInit = { method: "get" }
): Promise<HttpResponse<T>> {
    return await http<T>(new Request(path, args));
};

/*
 * Helper method to make a POST request to a web service.
 */
export async function post<T>(
    path: string,
    body: any,
    args: RequestInit = { method: "post", body: JSON.stringify(body), headers: { 'Content-Type': 'application/json' } }
): Promise<HttpResponse<T>> {
    return await http<T>(new Request(path, args));
};