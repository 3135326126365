import * as ko from 'knockout';
import { Computed, Observable, ObservableArray } from 'knockout';
import * as http from './http-helpers';
import { NewRequest } from './NewRequest';
import { Force, Department, DepartmentArea, DepartmentTeam, Template, User } from './SharePointModels';
import { PageSettings, InitialData } from './WizardSettings';
import { JsonApiResponse } from './HttpResponses';
import * as sw from 'smartwizard';
import { MgtPeoplePicker } from '@microsoft/mgt';
import { User as GraphUser } from '@microsoft/microsoft-graph-types';

export class NewSiteWizardViewModel {
    constructor(settings: PageSettings, initialData: InitialData) {
        if (!settings) {
            throw new Error("settings cannot be null");
        }

        if (!settings.wizardClientId) {
            throw new Error("wizardClientId cannot be null");
        }

        this.settings = settings;

        if (settings.twoOwnersRequired) {
            this.ownersValidationMessage = "NEP requires at least two owners to be provided";
            this.numberOwnersRequired = 2;
        } else {
            this.ownersValidationMessage = "Please enter at least one owner";
            this.numberOwnersRequired = 1;
        }

        if (!initialData) {
            throw new Error("initialData cannot be null");
        }

        if (!initialData.forces) {
            throw new Error("initialData.forces cannot be null");
        }
        this.Forces(initialData.forces);

        if (!initialData.templates) {
            throw new Error("initialData.templates cannot be null");
        }
        this.SiteTemplates(initialData.templates);

        // Wire up the selected event of the Force list
        this.SelectedForce.subscribe((newValue) => {
            if (newValue !== undefined && newValue !== null) {
                this.loadingDepartments(true);
                this.LoadDepartments(newValue).then((departments) => {
                    this.Departments(departments);
                    this.loadingDepartments(false);
                });
            } else {
                // If unselected then need to clear options for all other choices
                this.Departments.removeAll();
                this.DepartmentAreas.removeAll();
                this.DepartmentTeams.removeAll();
            }
        });

        // Wire up the selected event of the Department list
        this.SelectedDepartment.subscribe((newValue) => {
            if (newValue !== undefined && newValue !== null) {
                this.loadingDepartmentAreas(true);
                this.LoadDepartmentAreas(newValue).then((areas) => {
                    this.DepartmentAreas(areas);
                    this.loadingDepartmentAreas(false);
                })
            } else {
                // If unselected then need to clear options for areas and teams
                this.DepartmentAreas([]);
                this.DepartmentTeams([]);
            }
        });

        // Wire up the selected event of the Department Area list
        this.SelectedDepartmentArea.subscribe((newValue) => {
            if (newValue !== undefined && newValue !== null) {
                this.loadingDepartmentTeams(true);
                this.LoadDepartmentTeams(newValue).then((teams) => {
                    this.DepartmentTeams(teams);
                    this.loadingDepartmentTeams(false);
                })
            } else {
                // If unselected then need to clear options for teams
                this.DepartmentTeams([]);
            }
        });

        // Wire up the changed event of the Team Selection type
        this.teamSelectionType.subscribe((newValue) => {
            if (newValue === "existing") {
                // Blank out the user entered value
                this.AdditionalDepartmentTeam("");
            } else {
                // Blank out the selected existing team
                this.SelectedDepartmentTeam(null);
            }
        })
    }

    settings: PageSettings;
    numberOwnersRequired: number;
    ownersValidationMessage: string;
    CurrentUser: Observable<User> = ko.observable(null);
    State: Observable<string> = ko.observable("entry");
    ErrorMessage: Observable<string> = ko.observable("");

    step5Loaded: Observable<boolean> = ko.observable(false);
    teamSelectionType: Observable<string> = ko.observable("existing");
    loadingDepartments: Observable<boolean> = ko.observable(false);
    loadingDepartmentAreas: Observable<boolean> = ko.observable(false);
    loadingDepartmentTeams: Observable<boolean> = ko.observable(false);

    // Show validation
    ShowStep2Validation: Observable<boolean> = ko.observable(false);
    ShowStep3Validation: Observable<boolean> = ko.observable(false);
    ShowStep4Validation: Observable<boolean> = ko.observable(false);
    ShowStep5Validation: Observable<boolean> = ko.observable(false);

    // Available items
    SiteTemplates: ObservableArray<Template> = ko.observableArray([]);
    Forces: ObservableArray<Force> = ko.observableArray([]);
    Departments: ObservableArray<Department> = ko.observableArray([]);
    DepartmentAreas: ObservableArray<DepartmentArea> = ko.observableArray([]);
    DepartmentTeams: ObservableArray<DepartmentTeam> = ko.observableArray([]);

    // Simple data fields
    Requestor: Computed<string> = ko.computed(() => {
        if (this.CurrentUser()) {
            return this.CurrentUser().displayName;
        } else {
            return '[Not Loaded]';
        }
    }, this);
    SiteName: Observable<string> = ko.observable('');
    SiteNameError:  Computed<boolean> = ko.computed(() => {
        const pattern = new RegExp(/^[0-9a-zA-Z_ -]+$/);
        const result = pattern.test(this.SiteName());
        return !result;
    }, this);
    Description: Observable<string> = ko.observable('');
    DescriptionLengthOk:  Computed<boolean> = ko.computed(() => {
        return this.Description() ? this.Description().length <= 254 : true;
    }, this);
    Rationale: Observable<string> = ko.observable('');
    RationaleLengthOk:  Computed<boolean> = ko.computed(() => {
        return this.Rationale() ? this.Rationale().length <= 254 : true;
    }, this);
    ShowInDirectory: Observable<boolean> = ko.observable(true);
    SiteUrl: Observable<string> = ko.observable('');
    SiteUrlOk: Computed<boolean> = ko.computed(() => {
        if (this.SiteUrl()) {
            if (this.SiteUrl().match(/^[\w-]+$/)) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }, this);
    // Selected items
    SelectedTemplate: Observable<string> = ko.observable(null);
    SelectedTemplateText:  Computed<string> = ko.computed(() => {
        const selected = Number(this.SelectedTemplate());
        if (selected) {
            const selectedTemplate = ko.utils.arrayFirst(this.SiteTemplates(), (item) => {
                return item.id === selected;
            });

            if (selectedTemplate) {
                return selectedTemplate.name;
            }
        }

        return '<not selected>';
    }, this);
    SelectedForce: Observable<number> = ko.observable(null);
    SelectedForceText:  Computed<string> = ko.computed(() => {
        const selected = this.SelectedForce();
        if (selected) {
            const selectedForce = ko.utils.arrayFirst(this.Forces(), (item) => {
                return item.id === selected;
            });

            if (selectedForce) {
                return selectedForce.name;
            }
        }

        return '<not selected>';
    }, this);
    SelectedDepartment: Observable<number> = ko.observable(null);
    SelectedDepartmentText:  Computed<string> = ko.computed(() => {
        const selected = this.SelectedDepartment();
        if (selected) {
            const selectedDep = ko.utils.arrayFirst(this.Departments(), (item) => {
                return item.id === selected;
            });

            if (selectedDep) {
                return selectedDep.name;
            }
        }

        return '<not selected>';
    }, this);
    SelectedDepartmentArea: Observable<number> = ko.observable(null);
    SelectedDepartmentAreaText:  Computed<string> = ko.computed(() => {
        const selected = this.SelectedDepartmentArea();
        if (selected) {
            const selectedDepArea = ko.utils.arrayFirst(this.DepartmentAreas(), (item) => {
                return item.id === selected;
            });

            if (selectedDepArea) {
                return selectedDepArea.name;
            }
        }

        return '<not selected>';
    }, this);
    IsDepartmentAreaValid: Computed<boolean> = ko.computed(() => {
        const selected = this.SelectedDepartmentArea();
        if (selected) {
            const selectedDepArea = ko.utils.arrayFirst(this.DepartmentAreas(), (item) => {
                return item.id === selected;
            });

            if (selectedDepArea) {
                return selectedDepArea.hasDepartmentTerm;
            }
        }

        return true;
    }, this);
    SelectedDepartmentTeam: Observable<number> = ko.observable(null);
    AdditionalDepartmentTeam: Observable<string> = ko.observable();
    SelectedDepartmentTeamText: Computed<string> = ko.computed(() => {
        const selected = this.SelectedDepartmentTeam();
        if (selected) {
            const selectedDepTeam = ko.utils.arrayFirst(this.DepartmentTeams(), (item) => {
                return item.id === selected;
            });

            if (selectedDepTeam) {
                return selectedDepTeam.name;
            }
        }
        else {
            const additional = this.AdditionalDepartmentTeam();
            if (additional) {
                return additional;
            }
        }

        return '<not selected>';
    }, this);
    SiteUrlPrefix: Computed<string> = ko.computed(() => {
        const selected = Number(this.SelectedTemplate());
        if (selected) {
            const selectedTemplate = ko.utils.arrayFirst(this.SiteTemplates(), (item) => {
                return item.id === selected;
            });

            if (selectedTemplate) {
                if (!selectedTemplate.autoGenerateUrl && selectedTemplate.mailboxPattern) {
                    return selectedTemplate.mailboxPattern;
                }
            }
        }

        return "";
    }, this);
    CanEnterSiteUrl: Computed<boolean> = ko.computed(() => {
        const selected = Number(this.SelectedTemplate());
        if (selected) {
            const selectedTemplate = ko.utils.arrayFirst(this.SiteTemplates(), (item) => {
                return item.id === selected;
            });

            if (selectedTemplate) {
                return !selectedTemplate.autoGenerateUrl;
            }
        }

        return false;
    }, this);
    FullSiteUrl: Computed<string> = ko.computed(() => {
        const selected = Number(this.SelectedTemplate());

        if (selected) {
            const selectedTemplate = ko.utils.arrayFirst(this.SiteTemplates(), (item) => {
                return item.id === selected;
            });

            if (selectedTemplate) {
                if (!selectedTemplate.autoGenerateUrl && selectedTemplate.mailboxPattern) {
                    return selectedTemplate.mailboxPattern + this.SiteUrl();
                } else {
                    return this.SiteUrl();
                }
            }
        }

        return "<Autogenerated>";
    }, this);
    RequiresApproval: Computed<boolean> = ko.computed(() => {
        let requiresApproval = false;

        if (this.SelectedDepartment()) {
            const selectedDep = ko.utils.arrayFirst(this.Departments(), (item) => {
                return item.id === this.SelectedDepartment();
            });

            if (selectedDep && selectedDep.requiresDepartmentApproval) {
                requiresApproval = true;
            } else if (this.SelectedDepartmentArea()) {
                const selectedDepArea = ko.utils.arrayFirst(this.DepartmentAreas(), (item) => {
                    return item.id === this.SelectedDepartmentArea();
                });

                if (selectedDepArea && selectedDepArea.requiresDepartmentApproval) {
                    requiresApproval = true;
                }
            }
        }

        return requiresApproval;
    }, this);
    ShouldShowVisitors: Computed<boolean> = ko.computed(() => {
        let showVisitors = false;
        const selected = Number(this.SelectedTemplate());
        if (selected) {
            const selectedTemplate = ko.utils.arrayFirst(this.SiteTemplates(), (item) => {
                return item.id === selected;
            });

            if (selectedTemplate) {
                showVisitors = (selectedTemplate.type === "SharePointSite");
            }
        }

        return showVisitors;
    }, this);
    OwnersLabel: Computed<string> = ko.computed(() => {
        let label = "Team Owners";
        const selected = Number(this.SelectedTemplate());
        if (selected) {
            const selectedTemplate = ko.utils.arrayFirst(this.SiteTemplates(), (item) => {
                return item.id === selected;
            });

            if (selectedTemplate && selectedTemplate.type === "SharePointSite") {
                label = "Site Owners";
            }
        }

        return label;
    }, this);
    MembersLabel: Computed<string> = ko.computed(() => {
        let label = "Team Members";
        const selected = Number(this.SelectedTemplate());
        if (selected) {
            const selectedTemplate = ko.utils.arrayFirst(this.SiteTemplates(), (item) => {
                return item.id === selected;
            });

            if (selectedTemplate && selectedTemplate.type === "SharePointSite") {
                label = "Site Members";
            }
        }

        return label;
    }, this);

    // Collections of users
    SiteOwners: ObservableArray<string> = ko.observableArray([]);
    SiteMembers: ObservableArray<string> = ko.observableArray([]);
    SiteVisitors: ObservableArray<string> = ko.observableArray([]);
    SelectedVisitorGroup: Observable<string> = ko.observable("0");
    SiteVisitorsGroups: Computed<string> = ko.computed(() => {
        if (this.SelectedVisitorGroup() === "1") {
            return "Everyone (except External Users)";
        } else {
            return "None";
        }
    }, this);

    // People Picker controls
    SiteOwnersPicker: MgtPeoplePicker;
    SiteMembersPicker: MgtPeoplePicker;
    SiteVisitorsPicker: MgtPeoplePicker;

    SiteOwnersOk: Computed<boolean> = ko.computed(() => {
        if (this.SiteOwners() && this.SiteOwners().length >= this.numberOwnersRequired) {
            return true;
        } else {
            return false;
        }
    }, this);

    // Wizard ui functions
    public InitialiseWizard() {
        const createButtonId = "createButton";
        const nextButtonClass = ".sw-btn-next";

        const createButton = $(`<button id='${createButtonId}' style='display:none'></button>`).text("Create").addClass("btn btn-primary").attr("data-bind", "click: CreateRequest");
        const resetButton = $("<button id='resetButton'></button>").text("Reset").addClass("btn btn-secondary floatLeft").attr("data-bind", "click: ResetWizard");

        const wizardSettings: sw.SmartWizardOptions = {
            autoAdjustHeight: false,
            enableURLhash: false,
            toolbarSettings: {
                toolbarExtraButtons: [createButton, resetButton]
            },
            keyboardSettings: {
                keyNavigation: false
            }
        };

        $('#wizard').smartWizard(wizardSettings);
        $('#wizard').on("showStep", (e: JQuery.Event, anchorObject: object, stepIndex: number, stepDirection: string) => {
            $(`#${createButtonId}`).hide();
            $(nextButtonClass).show();

            const nextStepIndex = stepDirection === 'backward' ? stepIndex - 1 : stepIndex + 1;

            switch (nextStepIndex) {
                case 1:
                    if (stepDirection !== 'backward') {
                        $("input[name='siteTemplate']")[0].focus();
                    }

                    if (this.SiteTemplates().length === 1) {
                        // If there is only one template automatically select it
                        this.SelectedTemplate(this.SiteTemplates()[0].id.toString());
                    }

                    break;

                case 2:
                    if (stepDirection !== 'backward') {
                        $("#siteNameText").focus();
                    }
                    break;

                case 3:
                    if (stepDirection !== 'backward') {
                        $("#forceList").focus();
                    }
                    break;

                case 4:
                    if (!this.step5Loaded()) {
                        this.SiteOwnersPicker = document.querySelector('#siteOwnersPicker');
                        this.SiteMembersPicker = document.querySelector('#siteMembersPicker');
                        this.SiteVisitorsPicker = document.querySelector('#siteVisitorsPicker');

                        if (this.CurrentUser()) {
                            // Add the current user as an owner by default
                            this.SiteOwnersPicker.selectUsersById([this.CurrentUser().userId]);
                        }
                        this.step5Loaded(true);
                    }
                    if (stepDirection !== 'backward') {
                        $("#siteOwnersPicker").focus();
                    }
                    break;

                case 5:
                    $(`#${createButtonId}`).show();
                    $(nextButtonClass).hide();
                    break;
            }

            // Return to the top of the window
            document.documentElement.scrollTop = 0;
        });
        $('#wizard').on("leaveStep", (e: unknown, anchorObject: object, currentStepNumber: number, nextStepNumber: number, stepDirection: string) => {
            let stepValid: boolean;

            switch (currentStepNumber) {
                case 1:
                    stepValid = this.ValidateStep2();
                    this.ShowStep2Validation(true);
                    break;

                case 2:
                    stepValid = this.ValidateStep3();
                    this.ShowStep3Validation(true);
                    break;

                case 3:
                    stepValid = this.ValidateStep4();
                    this.ShowStep4Validation(true);
                    break;

                case 4:
                    stepValid = this.ValidateStep5();
                    this.ShowStep5Validation(true);
                    break;

                default:
                    stepValid = true;
            }

            this.WizardSetError(currentStepNumber, !stepValid);

            // If the user is going backwards they can even if the current step is invalid
            return (stepDirection === "backward") || stepValid;
        });
    };

    public CreateRequest() {
        // First validate all steps again
        const isValid: boolean = this.ValidateStep2() && this.ValidateStep3() && this.ValidateStep4() && this.ValidateStep5();

        if (!isValid) {
            alert('There is an error, please fix and try again');
            return;
        } else if (this.CurrentUser() === null) {
            alert('Can not submit request as we have not been able to confirm your identity in the background');
        } else {
            // All good so lets save the item
            this.SaveItem();
        }
    };

    public ResetWizard() {
        this.SelectedTemplate(null);
        this.ShowStep2Validation(false);

        this.SiteName("");
        this.Description("");
        this.Rationale("");
        this.ShowStep3Validation(false);

        this.SelectedDepartmentTeam(null);
        this.AdditionalDepartmentTeam("");
        this.teamSelectionType("existing");
        this.SelectedDepartmentArea(null);
        this.SelectedDepartment(null);
        this.SelectedForce(null);
        this.ShowInDirectory(true);
        this.ShowStep4Validation(false);

        this.SiteOwners([]);
        if (this.SiteOwnersPicker) {
            this.SiteOwnersPicker.selectedPeople = [];
            this.SiteOwnersPicker.selectUsersById([this.CurrentUser().userId]);
        }
        this.SiteMembers([]);
        if (this.SiteMembersPicker) {
            this.SiteMembersPicker.selectedPeople = [];
        }
        this.SiteVisitors([]);
        if (this.SiteVisitorsPicker) {
            this.SiteVisitorsPicker.selectedPeople = [];
        }
        this.SelectedVisitorGroup("0");
        this.ShowStep5Validation(false);

        $("#wizard").smartWizard("reset");
        $("#wizard").smartWizard("stepState", [0, 1, 2, 3, 4, 5], "error-off");
        this.State("entry");
    }

    public ShowWizardAfterError() {
        this.State("entry");
    }

    public WizardSetError(stepNum: number, inError: boolean) {
        let errorState = "error-off";
        if (inError) {
            errorState = "error-on";
        }

        $('#wizard').smartWizard("stepState", [stepNum], errorState);
    };

    private async LoadDepartments (forceId: number): Promise<Department[]> {
        try {
            const url: string = this.settings.fetchDepartmentsUrl + forceId;
            const departmentsResponse = http.get<Department[]>(url);
            return (await departmentsResponse).parsedBody;
        }
        catch {
            console.error("Failed to load departments for force with id " + forceId);
        }
    }

    private async LoadDepartmentAreas(departmentId: number): Promise<DepartmentArea[]> {
        try {
            const url: string = this.settings.fetchDepartmentAreasUrl + departmentId;
            const departmentAreasResponse = http.get<DepartmentArea[]>(url);
            return (await departmentAreasResponse).parsedBody;
        }
        catch {
            console.error("Failed to load department areas for department with id " + departmentId);
        }
    }

    private async LoadDepartmentTeams(departmentAreaId: number): Promise<DepartmentTeam[]> {
        try {
            const url: string = this.settings.fetchDepartmentTeamsUrl + departmentAreaId;
            const departmentTeamsResponse = http.get<DepartmentTeam[]>(url);
            return (await departmentTeamsResponse).parsedBody;
        }
        catch {
            console.error("Failed to load department teams for department area with id " + departmentAreaId);
        }
    }

    // Validation methods

    /*
     * Validate that the user has selected a template
     */
    public ValidateStep2(): boolean {
        if (this.SelectedTemplate()) {
            return true;
        }
        else {
            return false;
        }
    }

    /*
     * Validate that basic details have been added
     */
    public ValidateStep3(): boolean {
        const siteNameEntered = this.SiteName().length > 0;
        const siteDescriptionEntered = this.Description().length > 0;
        const rationaleEntered = this.Rationale().length > 0;

        const siteDescriptionMaxLength = this.DescriptionLengthOk();
        const rationaleMaxLength = this.RationaleLengthOk();

        const siteNameAllowed = !this.SiteNameError();

        return siteNameEntered && siteDescriptionEntered && rationaleEntered && siteNameAllowed && siteDescriptionMaxLength && rationaleMaxLength;
    }

    /*
     * Validate that the user has selected either an existing team or they chose to add a new one
     */
    public ValidateStep4(): boolean {
        if (this.IsDepartmentAreaValid() && (this.SelectedDepartmentTeam() || this.AdditionalDepartmentTeam())) {
            if (!this.CanEnterSiteUrl()) {
                return true;
            } else if (this.CanEnterSiteUrl() && this.SiteUrl().length > 0 && this.SiteUrlOk()) {
                return true;
            } else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    /*
     * Validate that the permissions entered are valid
     */
    public ValidateStep5(): boolean {
        // Copy the people from the pickers into the observable
        this.PopulateObservableFromPicker("siteOwnersPicker", this.SiteOwners);
        this.PopulateObservableFromPicker("siteMembersPicker", this.SiteMembers);
        this.PopulateObservableFromPicker("siteVisitorsPicker", this.SiteVisitors);

        if (this.SiteOwners() === undefined || this.SiteOwners() === null) {
            return false;
        } else {
            if (this.settings.twoOwnersRequired) {
                return this.SiteOwners().length >= 2;
            } else {
                return this.SiteOwners().length >= 1;
            }
        }
    }

    /*
     * Populate an observable from a people picker control
     */
    private PopulateObservableFromPicker(pickerName: string, obs: ObservableArray<string>) {
        const picker = document.querySelector("#" + pickerName) as MgtPeoplePicker;

        if (picker) {
            obs([]); // Clear the observable first

            if (picker.selectedPeople) {
                /* tslint:disable-next-line:prefer-for-of
                 * disabling rule as need to cast object being iterated which is not possible in for of loop */
                for (let i = 0; i < picker.selectedPeople.length; i++) {
                    const person = picker.selectedPeople[i] as GraphUser;

                    obs.push(person.userPrincipalName);
                }
            }
        }
    }

    // Save methods
    public async SaveItem(): Promise<boolean> {
        // Show progress to the user
        this.State("submitting");

        const spSiteUrl = this.CanEnterSiteUrl() ? this.SiteUrlPrefix() + this.SiteUrl() : null;

        const item: NewRequest = {
            requestor: this.Requestor(),
            templateId: Number(this.SelectedTemplate()),
            name: this.SiteName(),
            description: this.Description(),
            rationale: this.Rationale(),
            showInDirectory: this.ShowInDirectory(),
            forceId: this.SelectedForce(),
            departmentId: this.SelectedDepartment(),
            departmentAreaId: this.SelectedDepartmentArea(),
            departmentTeamId: this.SelectedDepartmentTeam() ? this.SelectedDepartmentTeam() : null,
            alternateDepartmentTeam: this.AdditionalDepartmentTeam() ? this.AdditionalDepartmentTeam() : null,
            owners: this.SiteOwners(),
            members: this.SiteMembers(),
            visitors: this.SiteVisitors(),
            siteUrlFinalPortion: spSiteUrl,
            additionalVisitorGroup: Number(this.SelectedVisitorGroup())
        };

        try {
            const url: string = this.settings.createRequestUrl;

            const saveResponse = await http.post<JsonApiResponse>(url, item)

            if (saveResponse.parsedBody.success) {
                this.State("submitted");
                return true;
            } else {
                this.State("error");
                this.ErrorMessage(saveResponse.parsedBody.message);
                return false;
            }
        }
        catch (ex) {
            alert('An error occcurred trying to save the request. ' + ex);
            return false;
        }
    };

    Step2Valid:  Computed<boolean> = ko.computed(() => {
        return this.ValidateStep2();
    }, this, { deferEvaluation: true });

    Step3Valid:  Computed<boolean> = ko.computed(() => {
        return this.ValidateStep3();
    }, this, { deferEvaluation: true });

    Step4Valid:  Computed<boolean> = ko.computed(() => {
        return this.ValidateStep4();
    }, this, { deferEvaluation: true });

    Step5Valid:  Computed<boolean> = ko.computed(() => {
        return this.ValidateStep5();
    }, this, { deferEvaluation: true });
}
