import * as $ from 'jquery';
import * as ko from 'knockout';
import * as tvp from './AppPageViewModel';
import { Force, Template } from './SharePointModels';
import { PageSettings, InitialData } from './WizardSettings';

// These values are provided from the server on page load and initialised in the ASP.NET page
declare let forces: Force[];
declare let templates: Template[];
declare let fetchDepartmentsUrl: string;
declare let fetchDepartmentAreasUrl: string;
declare let fetchDepartmentTeamsUrl: string;
declare let createRequestUrl: string;
declare let fetchRequestsUrl: string;
declare let wizardClientId: string;
declare let teamsAppClientId: string;
declare let wizardAppUrl: string;
declare let graphProxyUrl: string;
declare let twoOwnersRequired: boolean;

// GLOBAL Variables
let appPageVM: tvp.AppPageViewModel;

// Main functions
function setMaxLength() {
    $("textarea.checkMax").each(function () {
        const intMax = $(this).attr("maxlength");
        $(this).after("<div><span id='" + this.id + "Counter'>" + intMax + "</span> characters remaining</div>");
    });
}

function checkMaxLength(element: HTMLElement) {
    const intCount = ($("#" + element.id).val() as string).replace(/(\r\n)/g, '--').length;
    const intMax = parseInt($("#" + element.id).attr("maxlength"), 10);
    const counterID = "#" + element.id + "Counter";
    $(counterID).text(intMax - intCount);
    if (intCount > intMax) { $(counterID).css("color", "#990000"); } // Over
    else if (intCount > (intMax * .8)) { $(counterID).css("color", "#FF9933"); } // Warning at 80%
    else if (intCount < (intMax * .8)) { $(counterID).css("color", "#006600"); } // Good
}

function initialiseApp() {
    const initialData: InitialData = { forces, templates };

    const settings: PageSettings = {
        fetchDepartmentsUrl,
        fetchDepartmentAreasUrl,
        fetchDepartmentTeamsUrl,
        createRequestUrl,
        fetchRequestsUrl,
        wizardClientId,
        teamsAppClientId,
        wizardAppUrl,
        graphProxyUrl,
        twoOwnersRequired
    };

    appPageVM = new tvp.AppPageViewModel(settings, initialData);

    $("#loadingWizard").hide();
    $("#wizard").show();

    ko.applyBindings(appPageVM, $("#appPage")[0]);

    $("textarea.checkMax").each(function () {
        $(this).on("click mouseover keyup change", function () { checkMaxLength(this); });
    });

    // Enable bootstrap tooltips whereever they are
    $("[data-toggle='tooltip']").tooltip();
};

$(document).ready(() => {
    initialiseApp();

    setMaxLength();
});