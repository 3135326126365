(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("jQuery"));
	else if(typeof define === 'function' && define.amd)
		define(["jQuery"], factory);
	else if(typeof exports === 'object')
		exports["nsrw"] = factory(require("jQuery"));
	else
		root["nsrw"] = factory(root["jQuery"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__609__) {
return 